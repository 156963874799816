import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FWBanner from '../../../components/fullWidthBanner';
import FWTextBanner from '../../../components/fullWidthTextBanner';
import FWCard from '../../../components/fullWidthCard';


const useStyles = makeStyles((theme) => ({
    promoHeader: theme.typography.promoHeader,
    paragraph: {
        marginBottom: theme.spacing(5),
    },
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),

    },
}))

export default function Values(props) {
    const classes = useStyles()
    const locale = 'en-EU'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='Our Values' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWBanner imgName='degerlerimiz-desktop.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <Grid container className={classes.container}>
                        <FWTextBanner title='We just love Pets!' />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Say NO! To Animal Abuse' subtitle='We try to support the rehabilitation of abused animals who we are aware of. If
you know an animal in need of help please contact us.' image='degerlerimiz-hayvan-istismarı.jpg' bg />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Providing Food For Stray Animals' subtitle='We support our four-legged-friends who do not have homes. We provide them with
food through associations. Our most important goal and desire is to make sure that
no animal starves.' image='degerlerimiz-ac-hayvan-kalmasin.jpg' position='right' bg />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Quality and Taste' subtitle="We offer foods that are prepared with fresh and high-quality ingredients from
suppliers in the EU and that have proven their taste with high palatability." image='degerlerimiz-kalite.jpg' bg />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='As Fresh As It Gets' subtitle='From production to shelves, we deliver our foods as quickly as possible with perfect
logistics planning and process management. In this way, our products are always
presented fresh on the shelf no matter where you are in the world.' image='degerlerimiz-taze.jpg' position='right' bg />
                </Grid>

            </Grid>
        </Layout>
    )

}




